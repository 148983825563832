import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { RETURN_URL } from '@services/auth/param-names';
import { StorageService } from '@services/storage/storage.service';
import { LOGIN_URL } from '@tokens/login-url.token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private storageService = inject(StorageService);
  private defaultView = inject(DOCUMENT).defaultView;

  private loginUrl = inject(LOGIN_URL);

  async toLoginPage(navigateToProjectRoot = false) {
    this.logOut();
    let returnUrl;

    if (navigateToProjectRoot) {
      returnUrl = window.location.origin;
    } else {
      returnUrl = window.location.href;
    }

    const url = new URL(this.loginUrl);
    url.searchParams.set(RETURN_URL, returnUrl);

    this.defaultView?.location.replace(url);
  }

  logOut(): void {
    this.storageService.clearToken();
  }
}
