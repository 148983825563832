import { computed, inject, Injectable } from '@angular/core';
import { LfMenuItem, LfMenuSubItem } from '@leafio/ui/layout';
import { AclService } from '@acl/service/acl.service';
import { ProfileService } from '@services/profile/profile.service';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { CurrentCompanyService } from '@services/current-company/current-company.service';
import { APP_LOCALES } from '@constants/app-locales';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  profileService = inject(ProfileService);
  aclService = inject(AclService);
  router = inject(Router);
  auth = inject(AuthService);
  currentCompany = inject(CurrentCompanyService);

  protected company = computed(() => this.currentCompany.base());

  protected getPrefix(): string {
    return this.aclService.isUserInternal() ? `company/${this.company()?.system_name}/` : '';
  }

  protected getHomeItem(): LfMenuItem {
    return {
      name: '',
      url: '/',
      iconName: 'menu/logo-leafio',
      className: 'lf-menu-item_with-leafio-logo',
    };
  }

  protected getSettingsItem(): LfMenuItem {
    return {
      name: 'settings.title',
      url: this.getPrefix() + 'settings',
      iconName: 'menu/settings',
    };
  }

  protected getTasksItem(): LfMenuItem {
    return {
      name: 'tasks.title',
      url: this.getPrefix() + 'tasks',
      iconName: 'menu/tasks',
    };
  }

  protected getReportsItem(): LfMenuItem {
    return {
      name: 'reports.title',
      url: this.getPrefix() + 'reports',
      iconName: 'menu/matrix',
    };
  }

  protected getTopItemsForCompany(): LfMenuItem[] {
    if (!this.company()) {
      return [];
    }
    const items: LfMenuItem[] = [];

    if (this.aclService.isUserInternal() || this.aclService.isSomeAllowedFull(['tm_manager', 'tm_store_employee'])) {
      items.push(this.getTasksItem());
    }

    if (this.aclService.isUserInternal() || this.aclService.isSomeAllowedFull(['dashboard'])) {
      items.push(this.getReportsItem());
    }

    return items;
  }

  protected getBottomItemsForCompany(): LfMenuItem[] {
    if (!this.company()) {
      return [];
    }
    const items: LfMenuItem[] = [];

    if (this.aclService.isUserInternal()) {
      items.push(this.getSettingsItem());
    }

    return items;
  }

  protected getLocalizationItem(): LfMenuItem {
    const currentLocale = this.profileService.locale();

    const items: LfMenuSubItem[] = APP_LOCALES.map((locale) => {
      const checked = locale.code === currentLocale;
      return {
        name: locale.label,
        url: null,
        icon: 'globe',
        checked: checked,
        onClick: checked ? undefined : () => this.profileService.useLocale(locale.code),
      };
    });

    return {
      name: 'common.language',
      url: null,
      iconText: currentLocale.toUpperCase(),
      children: items,
    };
  }

  protected getManualSubItem(): LfMenuSubItem {
    let url = 'admin/manual';

    if (this.company()) {
      url = this.getPrefix() + 'manual';
    }

    return {
      name: 'manual.title',
      url: url,
      icon: 'question_circle',
    };
  }

  protected getProfileItem(): LfMenuItem {
    let profileSubheaderName = 'No User';
    let profileSubheaderDesc = 'error.load_data';
    const user = this.profileService.currentUser();

    if (user) {
      profileSubheaderName = user.full_name;
      profileSubheaderDesc = user.email;
    }

    return {
      name: 'profile.title',
      url: null,
      iconName: 'menu/user',
      submenuHeader: {
        name: profileSubheaderName,
        description: profileSubheaderDesc,
        url: null,
      },
      children: [
        this.getManualSubItem(),
        {
          name: 'profile.log_out',
          url: null,
          onClick: () => this.auth.toLoginPage(true),
          icon: 'log_out',
          accent: 'danger',
        },
      ],
    };
  }

  topItems = computed<LfMenuItem[]>(() => {
    return [this.getHomeItem(), ...this.getTopItemsForCompany()];
  });

  bottomItems = computed<LfMenuItem[]>(() => {
    return [this.getLocalizationItem(), ...this.getBottomItemsForCompany(), this.getProfileItem()];
  });
}
