import { inject, Injectable, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SNOW_PARTICLES_CONFIG } from '@services/snow/particles-config.const';
import { LfMenuSubItem } from '@leafio/ui/layout';
import { StorageService } from '@services/storage/storage.service';
import { environment } from '@environments/environment';

// @ts-ignore
declare const particlesJS: any;
// @ts-ignore
declare const pJSDom: null | any[];

const CDN_URL = 'https://cdn.jsdelivr.net/npm/particles.js@2.0.0/particles.min.js';
const CONTAINER_CLASS = 'app-snow-container';
const CONTAINER_ID = 'app-snow-container';

// starts on 1 Dec 2024 (month are numbered from 0)
const CURRENT_SEASON_START = new Date(2024, 11, 1);
// ends on 9 Jan 2025 at 00:00
const CURRENT_SEASON_END = new Date(2025, 0, 9);

@Injectable({
  providedIn: 'root',
})
export class SnowService {
  protected defaultView = inject(DOCUMENT).defaultView;
  protected storageService = inject(StorageService);

  protected _debug = false;

  protected snowy = false;
  protected isSeasonActive = false;

  menuItem = signal<LfMenuSubItem | null>(null);

  constructor() {
    this.init();
    this.initDevelopManualMode();
  }

  protected isCurrentSeasonActive() {
    const today = new Date();

    if (this._debug) {
      return true;
    }

    return today >= CURRENT_SEASON_START && today <= CURRENT_SEASON_END;
  }

  init() {
    this.isSeasonActive = this.isCurrentSeasonActive();

    if (!this.isSeasonActive) {
      return;
    }

    let snowMode = this.storageService.get(`advent_snow_mode`);
    if (snowMode === null) {
      snowMode = true;
    }

    if (snowMode) {
      void this.letItSnow();
    }
    this.updateMenuItem();
  }

  updateMenuItem() {
    if (!this.isSeasonActive) {
      return this.menuItem.set(null);
    }
    if (this.snowy) {
      this.menuItem.set({
        name: 'common.stop_snow',
        url: null,
        icon: 'snowflake_crossed',
        onClick: () => this.stopSnow(),
      });
    } else {
      this.menuItem.set({
        name: 'Let it snow!',
        url: null,
        icon: 'snowflake',
        onClick: () => this.letItSnow(),
      });
    }
  }

  initDevelopManualMode() {
    if (environment.production) {
      return;
    }
    // @ts-ignore
    window['enableAdventSeason'] = () => {
      this._debug = true;
      void this.init();
    };
  }

  async letItSnow() {
    if (!this.defaultView || this.snowy) {
      return;
    }

    try {
      await this.loadScript();
      this.injectContainer();
      this.initParticles();
      this.snowy = true;
      this.storageService.set(`advent_snow_mode`, true);
      this.updateMenuItem();
    } catch (e) {
      /* empty */
    }
  }

  stopSnow() {
    if (!this.defaultView) {
      return;
    }
    this.storageService.set(`advent_snow_mode`, false);

    location.reload();
  }

  protected injectContainer() {
    const snowContainer = this.defaultView!.document.createElement('div');
    snowContainer.classList.add(CONTAINER_CLASS);
    snowContainer.id = CONTAINER_ID;
    this.defaultView!.document.body.append(snowContainer);
  }

  protected loadScript() {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = CDN_URL;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(`Failed to load script: ${CDN_URL}`);
      document.body.appendChild(script);
    });
  }

  protected initParticles() {
    particlesJS(CONTAINER_ID, SNOW_PARTICLES_CONFIG);
  }
}
